<div class="dxp-container">
  <dxp-footer data-theme="dxp-theme-black" logo="false" quicklinks="false" contentlist="false" footerlinks="true"
    sociallinks="false" countrylanguageselector="flase" copyright="true" emailsubscription="false">
    <dxp-content-list slot="content-link-list" orientation="horizontal">
      <dxp-content-list-item subtitle="{{dxp.localeTranslator.translate('$.FOOTER.PRIVACY')}}"
        href="https://www.mastercard.us/en-us/vision/corp-responsibility/commitment-to-privacy/privacy.html"
        ariaLabel="{{dxp.localeTranslator.translate('$.FOOTER.PRIVACY_ARIA_LABEL')}}" target="true">
      </dxp-content-list-item>
      <dxp-content-list-item subtitle="{{dxp.localeTranslator.translate('$.FOOTER.TNC')}}"
        href="https://asset.mastercard.com/content/dam/gdpr/Digital-Experience-Platform/DXP_Portal_TERMS_OF_USE_Initial_Draft_LS.pdf"
        ariaLabel="{{dxp.localeTranslator.translate('$.FOOTER.TNC_ARIA_LABEL')}}" target="true">
      </dxp-content-list-item>
    </dxp-content-list>
    <dxp-copyright text='© 1994-2025 Mastercard' slot='copyright'>
    </dxp-copyright>
  </dxp-footer>
</div>
